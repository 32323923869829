var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"max-width":"1280px"},attrs:{"fluid":""}},[_c('v-layout',{attrs:{"align-start":""}},[_c('v-flex',[_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers,"items":_vm.movimientos,"items-per-page":15,"dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"hidden-sm-and-down"},[_vm._v("MOVIMIENTOS")]),_c('v-divider',{staticClass:"mx-2 hidden-sm-and-down",attrs:{"inset":"","vertical":""}}),_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","small":"","color":"print"},on:{"click":function($event){return _vm.crearPDF()}}},[_c('v-icon',[_vm._v("print")])],1),_c('v-spacer'),_c('v-autocomplete',{staticClass:"mr-1",attrs:{"dense":"","search-input":_vm.buscar_usuario,"disabled":!_vm.esAdministrador,"items":_vm.usuarios,"label":"Usuario","rules":[function (v) { return !!v || 'Nombres es requerido'; }],"clearable":"","hide-details":""},on:{"keyup":function($event){return _vm.selectUsuario()},"update:searchInput":function($event){_vm.buscar_usuario=$event},"update:search-input":function($event){_vm.buscar_usuario=$event},"change":_vm.listar},model:{value:(_vm.idusuario),callback:function ($$v) {_vm.idusuario=$$v},expression:"idusuario"}}),_c('v-select',{staticClass:"mr-1",attrs:{"dense":"","items":_vm.tipo_servicios,"label":"Moneda","hide-details":""},on:{"change":_vm.listar},model:{value:(_vm.moneda),callback:function ($$v) {_vm.moneda=$$v},expression:"moneda"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticStyle:{"max-width":"90px"},attrs:{"dense":"","readonly":"","hide-details":""},model:{value:(_vm.fecha_formateada),callback:function ($$v) {_vm.fecha_formateada=$$v},expression:"fecha_formateada"}},on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menu = false},"change":function($event){_vm.fechaseleccionada(_vm.fecha), _vm.listar()}},model:{value:(_vm.fecha),callback:function ($$v) {_vm.fecha=$$v},expression:"fecha"}})],1),_c('v-spacer')],1)]},proxy:true},{key:"item.tipo_comprobante",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.serie_comprobante + "-" + item.num_comprobante))])]}},{key:"item.moneda",fn:function(ref){
var item = ref.item;
return [(item.moneda == 'S')?[_c('span',[_vm._v("S/")])]:[_c('span',[_vm._v("$")])]]}},{key:"item.ingreso",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('span',[_vm._v(_vm._s(_vm._f("formatearNumero")(item.ingreso)))])])]}},{key:"item.egreso",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-right"},[_c('span',[_vm._v(_vm._s(_vm._f("formatearNumero")(item.egreso)))])])]}},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatearFecha")(item.fecha)))])]}}])},[_c('template',{slot:"body.append"},[(_vm.totalsN.ingreso != 0 || _vm.totalsN.egreso != 0)?_c('tr',[_c('td',{staticClass:"text-right font-weight-bold",attrs:{"colspan":6}},[_vm._v("TOTAL NO AFECTA CAJA:")]),_c('td',{staticClass:"text-right font-weight-bold"},[_vm._v(_vm._s(_vm._f("formatearNumero")(_vm.common.roundOut(_vm.totalsN.ingreso, 2)))+"\n            ")]),_c('td',{staticClass:"text-right font-weight-bold"},[_vm._v(_vm._s(_vm._f("formatearNumero")(_vm.common.roundOut(_vm.totalsN.egreso, 2)))+"\n            ")])]):_vm._e()]),_c('template',{slot:"body.append"},[_c('tr',[_c('td',{staticClass:"text-right font-weight-bold",attrs:{"colspan":6}},[_vm._v("TOTAL AFECTA CAJA:")]),_c('td',{staticClass:"text-right font-weight-bold"},[_vm._v(_vm._s(_vm._f("formatearNumero")(_vm.common.roundOut(_vm.totalsA.ingreso, 2)))+"\n            ")]),_c('td',{staticClass:"text-right font-weight-bold"},[_vm._v(_vm._s(_vm._f("formatearNumero")(_vm.common.roundOut(_vm.totalsA.egreso, 2)))+"\n            ")])])]),_c('template',{slot:"body.append"},[_c('tr',[_c('td',{staticClass:"text-right font-weight-bold",attrs:{"colspan":6}},[_vm._v("SALDO INICIAL CAJA:")]),_c('td',{staticClass:"text-right font-weight-bold"},[_vm._v(_vm._s(_vm._f("formatearNumero")(_vm.common.roundOut(_vm.moneda == 'S' ? _vm.saldoInicialMN : _vm.saldoFinalME,
              2))))])])]),_c('template',{slot:"body.append"},[_c('tr',[_c('td',{staticClass:"text-right font-weight-bold",attrs:{"colspan":6}},[_vm._v("SALDO FINAL CAJA:")]),_c('td',{staticClass:"text-right font-weight-bold"},[_vm._v(_vm._s(_vm._f("formatearNumero")(_vm.common.roundOut((_vm.moneda == 'S' ? _vm.saldoInicialMN : _vm.saldoFinalME)
              +
              _vm.totalsA.ingreso -
              _vm.totalsA.egreso, 2))))])])])],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }