var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"max-width":"1280px"},attrs:{"fluid":""}},[_c('v-layout',{attrs:{"alig-start":""}},[_c('v-flex',[_c('v-data-table',{staticClass:"elevation-3",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.detalles,"items-per-page":15,"item-key":"idmovimiento","show-expand":"","dense":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"hidden-sm-and-down"},[_vm._v("ANULACIONES")]),_c('v-divider',{staticClass:"mx-2 hidden-sm-and-down",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-text-field',{staticClass:"mr-1",attrs:{"dense":"","label":"Búsqueda","single-line":"","hide-details":""},on:{"keyup":function($event){return _vm.listar()}},model:{value:(_vm.buscar),callback:function ($$v) {_vm.buscar=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"buscar"}}),_c('v-spacer'),_c('v-menu',{attrs:{"close-on-content-click":!_vm.menuFechaBuscar,"transition":"scale-transition","offset-y":"","min-width":"290px","close-on-click":_vm.dates.length == 2 ? true : false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({staticClass:"mr-1",staticStyle:{"max-width":"220px"},attrs:{"dense":"","readonly":"","hide-details":"","append-icon":"update"},on:{"click:append":function($event){return _vm.listar()}},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},on))]}}]),model:{value:(_vm.menuFechaBuscar),callback:function ($$v) {_vm.menuFechaBuscar=$$v},expression:"menuFechaBuscar"}},[_c('v-date-picker',{attrs:{"range":""},on:{"input":function($event){_vm.dates.length == 2 ? _vm.menuFechaBuscar = false : _vm.menuFechaBuscar = true},"change":function($event){return _vm.listar()}},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}})],1),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"330"},model:{value:(_vm.adModal),callback:function ($$v) {_vm.adModal=$$v},expression:"adModal"}},[_c('v-card',[_c('v-card-title',{staticClass:"justify-center"},[_vm._v("¿Anular Baja?\n                ")]),_c('v-card-text',[_vm._v("\n                  Estás a punto de\n                  "),_c('span',[_vm._v("Anular")]),_vm._v("\n                  la Baja: "+_vm._s(_vm.adBaja)+"\n                ")]),_c('v-card-actions',{staticClass:"py-0 pb-4 justify-center"},[_c('v-btn',{attrs:{"color":"info","small":"","outlined":""},on:{"click":_vm.anularCerrar}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"error","small":"","outlined":""},on:{"click":_vm.anular}},[_vm._v("Anular")])],1)],1)],1)],1)]},proxy:true},{key:"item.fecha",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatearFecha")(item.fecha)))])]}},{key:"item.fecha_comprobante",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("formatearFecha")(item.fecha_comprobante)))])]}},{key:"item.comp_referencia",fn:function(ref){
var item = ref.item;
return _vm._l((JSON.parse(item.ref_comprobante)),function(item,i){return _c('strong',{key:i,attrs:{"text":""}},[_vm._v(_vm._s(item.serie_comprobante)+"-"+_vm._s(item.num_comprobante))])})}},{key:"item.identificador",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.serie_comprobante + "-" + item.num_comprobante))])]}},{key:"item.estado",fn:function(ref){
var item = ref.item;
return [(item.estado == 'Aceptado')?[_c('v-chip',{staticClass:"white--text font-weight-bold",attrs:{"small":"","color":"success","label":""}},[_vm._v(_vm._s(item.estado))])]:(item.estado == 'Rechazado')?[_c('v-chip',{staticClass:"white--text font-weight-bold",attrs:{"small":"","color":"error","label":""}},[_vm._v(_vm._s(item.estado))])]:(item.estado == 'Enviado')?[_c('span',{staticClass:"orange--text"},[_vm._v(_vm._s(item.estado))])]:(item.estado == 'Por enviar')?[_c('span',{staticClass:"brown--text"},[_vm._v(_vm._s(item.estado))])]:(item.estado == 'En proceso')?[_c('span',{staticClass:"pink--text"},[_vm._v(_vm._s(item.estado))])]:[_c('span',[_vm._v(_vm._s(item.estado))])]]}},{key:"item.descargas",fn:function(ref){
var item = ref.item;
return [(item.estado == 'Enviado' || item.estado == 'Aceptado')?[_c('v-chip',{staticClass:"white--text",attrs:{"color":"cyan","x-small":""},on:{"click":function($event){return _vm.descargarXml(item)}}},[_vm._v("XML")])]:_vm._e(),(item.estado == 'Aceptado')?[_c('v-chip',{staticClass:"white--text",attrs:{"color":"teal","x-small":""},on:{"click":function($event){return _vm.descargarCdr(item)}}},[_vm._v("CDR")])]:_vm._e()]}},{key:"item.opciones",fn:function(ref){
var item = ref.item;
return [(item.estado == 'Enviado' && !isNaN(item.nro_ticket) || item.estado == 'En proceso')?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"x-small":"","color":"orange","loading":_vm.consultar,"disabled":_vm.consultar,"label":""},on:{"click":function($event){return _vm.consultarTicket(item)}}},'v-btn',attrs,false),on),[_vm._v("Enviar Baja")])]}}],null,true)},[_c('span',[_vm._v("Completar anulación")])])]:_vm._e(),(item.estado == 'Por enviar' || item.estado == 'Creado')?[_c('v-btn',{staticClass:"white--text",attrs:{"x-small":"","color":"brown","loading":_vm.consultar,"disabled":_vm.consultar,"label":""},on:{"click":function($event){return _vm.reenviarBaja(item)}}},[_vm._v("Reenviar")])]:_vm._e(),(_vm.usuario.idusuario == 1 && item.estado == 'Enviado' && !isNaN(item.nro_ticket) || item.estado == 'Creado' || item.estado == 'Por enviar' || item.estado == 'En proceso')?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"},on:{"click":function($event){return _vm.anularMostrar(item)}}},[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Anular")])])]:_vm._e()]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('strong',[_vm._v("Registrado por:")]),_vm._v("\n            "+_vm._s(item.usuario)+"\n            "),_c('br'),(item.msj_cdr.length > 0)?_c('strong',[_vm._v("SUNAT:")]):_vm._e(),_vm._v("\n            "+_vm._s(item.msj_cdr)+"\n          ")])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }